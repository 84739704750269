// Font Family
$poppins: 'Poppins', sans-serif;


// Colors ---------------
$white: #fff;
$black: #000;
$gray: #f7f7fd;


// Theme Color
$primary-one: #2B82D4;
$primary-two: #deebfd;
$primary-three: #005ad5;


// Text Color
$text-color-one: #121212;
$text-color-two: #a4a4a4;
$text-color-three: #6c6c6c;


// Light Color
$light-color-one: #e9ecef;
$light-color-two: #f4f6f7;
$light-color-three: #cbced1;


// Secondary Color
$secondary-color-one: #393852;
$secondary-color-two: #e5e5e8;
$secondary-color-three: #34334a;


// Success color
$success-color-one: #4da422;
$success-color-two: #e8f3e2;
$success-color-three: #438f1d;


// Danger color
$danger-color-one: #fc3832;
$danger-color-two: #fee5e4;
$danger-color-three: #dc312b;


// Warning color
$warning-color-one: #ffb400;
$warning-color-two: #fff5de;
$warning-color-three: #df9d00;


// Info color
$info-color-one: #00b8d8;
$info-color-two: #def5f9;
$info-color-three: #00a1bd;


// Extra 1 color
$Extra1-color-one: #32fbfc;
$Extra1-color-two: #e4fefe;
$Extra1-color-three: #2bdbdc;


// Extra 2 color
$Extra2-color-one: #f7e500;
$Extra2-color-two: #fdfbde;
$Extra2-color-three: #d8c800;


// Extra 3 color
$Extra3-color-one: #ef1063;
$Extra3-color-two: #fce0ea;
$Extra3-color-three: #d10e56;


// Extra 4 color
$Extra4-color-one: #9c09db;
$Extra4-color-two: #f2dffa;
$Extra4-color-three: #8807bf;


// Responsive Variables
$desktop: 'only screen and (min-width: 1400px)';
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';










