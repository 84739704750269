


/*===========================
      19.CONTACT css 
===========================*/



.contact-area{
    padding-top: 120px;
    padding-bottom: 130px;
    background-color: $light-color-two;
}

p{
    &.form-message.success,
    &.form-message.error {
        font-size: 16px;
        color: #333;
        background: #ddd;
        padding: 10px 15px;
        margin-top: 15px;
        margin-left: 15px;
        
        &.form-message.error{
            color: #f00;
        }
    }
}

.contact-map{
    & iframe{
        width: 100%;
        height: 550px;
        
        @media #{$xs}{
            height: 350px;
        }
    }
}


.contact-info{
    & .single-contact-info{
        & .contact-info-icon{
            & i{
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 24px;
                color: $white;
                border-radius: 50%;
            }
        }
        & .contact-info-content{
            padding-left: 20px;
            
            & .text{
                color: $text-color-one;
            }
        }
        
        &.contact-color-1{
            & .contact-info-icon{
                & i{
                    background-color: $text-color-one;
                }
            }
        }
        &.contact-color-2{
            & .contact-info-icon{
                & i{
                    background-color: $danger-color-one;
                }
            }
        }
        &.contact-color-3{
            & .contact-info-icon{
                & i{
                    background-color: $primary-three;
                }
            }
        }
    }
}

.contact-wrapper{
    
    & .contact-title{
        font-size: 32px;
        font-weight: 700;
        color: #000;
        
        @media #{$xs}{
            font-size: 26px;
        }
        
        & i{
            color: $text-color-one;
            margin-right: 8px;
        }
        
        & span{
            font-weight: 400;
        }
    }
}





