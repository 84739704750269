


/*===========================
        13.TEAM css 
===========================*/


/*===== TEAM STYLE ELEVEN =====*/

.team-style-eleven{
    position: relative;
    @include box-shadow ( 0px 8px 16px 0px rgba(72,127,255,0.1));
    
    & .team-image{
        & img{
            width: 100%;
        }
    }
    & .team-content{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $white;
        padding-top: 25px;
        padding-bottom: 25px;
        z-index: 5;
        @include transition(0.3s);
        
        @media #{$xs}{
            padding-top: 15px;
            padding-bottom: 15px;
        }
        
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: -webkit-linear-gradient(left, rgba(0,103,244,1) 0%, rgba(43,219,220,1) 100%);
            background: -o-linear-gradient(left, rgba(0,103,244,1) 0%, rgba(43,219,220,1) 100%);
            background: linear-gradient(to right, rgba(0,103,244,1) 0%, rgba(43,219,220,1) 100%);
            z-index: -1;
            @include transition(0.3s);
            opacity: 0;
        }
        
        & .team-social{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @include transition(0.3s);
            visibility: hidden;
            opacity: 0;
            
            & .social{
                background-color: $white;
                display: inline-block;
                padding: 10px 20px 6px;
                border-radius: 50px;
                
                & li{
                    display: inline-block;
                    margin: 0 8px;

                    & a{
                        font-size: 16px;
                        color: $text-color-two;
                        @include transition(0.3s);
                        
                        &:hover{
                            color: $primary-one;
                        }
                    }
                }
            }
        }
        & .team-name{
            & a{
                color: $text-color-one;
                font-size: 24px;
                font-weight: 600;
                @include transition(0.3s);
                
                @media #{$xs}{
                    font-size: 18px;
                }
            }
        }
        & .sub-title{
            font-size: 16px;
            color: $primary-one;
            @include transition(0.3s);
            
            @media #{$xs}{
                font-size: 14px;
            }
        }
    }
    
    &:hover{
        & .team-content{
            padding-top: 50px;
            
            @media #{$xs}{
                padding-top: 35px;
            }
            
            &::before{
                opacity: 1;
            }
            
            & .team-social{
                top: -20px;
                visibility: visible;
                opacity: 1;
            }
            & .team-name{
                & a{
                    color: $white;
                }
            }
            & .sub-title{
                color: $white;
            }
        }
    }
}


