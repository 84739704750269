ul.timeline-3 {
    list-style-type: none;
    position: relative;
  }
  ul.timeline-3:before {
    content: " ";
    background: #2B82D4;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }
  ul.timeline-3 > li {
    margin: 20px 0;
    padding-left: 20px;
  }
  ul.timeline-3 > li:before {
    content: " ";
    background: #2B82D4;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #2B82D4;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
  }