



/*===========================
      18.PORTFOLIO css 
===========================*/


.portfolio-area {
    padding-top: 120px;
    padding-bottom: 130px;
}

.portfolio-menu {
    & ul {
        & li {
            font-size: 16px;
            font-weight: 700;
            color: $text-color-three;
            line-height: 48px;
            padding: 0 30px;
            position: relative;
            z-index: 5;
            @include transition(0.3s);
            cursor: pointer;
            width: 100%;
            border-radius: 50px;
            overflow: hidden;
            margin-top: 4px;
            text-transform: uppercase;

            &:last-child{
                margin-right: 0;
            }

            @media #{$md}{
                font-size: 14px;
                padding: 0 26px;
            }

            @media #{$xs}{
                font-size: 14px;
                padding: 0 22px;
                line-height: 42px;
            }

            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: -webkit-linear-gradient(left, rgba(43, 130, 212, 1) 0%, rgba(43, 219, 220, 1) 100%);
                background: -o-linear-gradient(left, rgba(43, 130, 212, 1) 0%, rgba(43, 219, 220, 1) 100%);
                background: linear-gradient(to right, rgba(43, 130, 212, 1) 0%, rgba(43, 219, 220, 1) 100%);
                z-index: -1;
                opacity: 0;
                @include transition(0.3s);
            }

            &:hover,
            &.active {
                color: $white;

                &::before {
                    opacity: 1;
                }
            }
        }
    }
}

.single-portfolio {

    & .portfolio-image {
        position: relative;
        overflow: hidden;

        & img {
            width: 100%;
            @include transition(0.3s);
        }

        & .portfolio-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            @include transition(0.5s);
            background-color: rgba(255, 255, 255, 0.8);
            @include transform(scale(0.9));
            border-radius: 8px; 

            & .portfolio-content {
                padding: 16px;

                & .portfolio-icon {
                    position: relative;
                    display: inline-block;
                    margin: 0 20px;

                    & a {
                        font-size: 48px;
                        color: $primary-one;
                        position: relative;
                        z-index: 5;
                        line-height: 50px;
                    }

                    & .shape {
                        position: absolute;
                        top: 7px;
                        left: 0;
                    }
                }

            }
        }
    }

    &:hover {
        & .portfolio-overlay {
            opacity: 1;
            visibility: visible;
        }
    }
}







