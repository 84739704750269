/*-----------------------------------------------------------------------------------

    Template Name: Smash - Bootstrap Template
    Template URI: uideck.com
    Author: UIdeck
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
      01.COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #121212; }

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%; }

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none; }

a:focus,
a:hover {
  text-decoration: none; }

i,
span,
a {
  display: inline-block; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #121212;
  margin: 0px; }

h1 {
  font-size: 48px; }

h2 {
  font-size: 36px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 22px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

ul, ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none; }

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #121212;
  margin: 0px; }

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }

/*===== All Slick Slide Outline Style =====*/
.slick-slide {
  outline: 0; }

/*===== All Section Title Style =====*/
.section-title .title {
  font-size: 44px;
  font-weight: 600;
  color: #121212;
  line-height: 55px; }
  @media (max-width: 767px) {
    .section-title .title {
      font-size: 30px;
      line-height: 35px; } }
.section-title .text {
  font-size: 16px;
  line-height: 24px;
  color: #6c6c6c;
  margin-top: 24px; }

/*===== All Preloader Style =====*/
.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: #fff;
  z-index: 99999; }
  .preloader .loader {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    .preloader .loader .ytp-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 64px;
      margin-left: -32px;
      z-index: 18;
      pointer-events: none; }
      .preloader .loader .ytp-spinner .ytp-spinner-container {
        pointer-events: none;
        position: absolute;
        width: 100%;
        padding-bottom: 100%;
        top: 50%;
        left: 50%;
        margin-top: -50%;
        margin-left: -50%;
        -webkit-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
        -moz-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
        -o-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
        animation: ytp-spinner-linspin 1568.23529647ms linear infinite; }
        .preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          -moz-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          -o-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
          .preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-left {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            overflow: hidden;
            right: 50%; }
          .preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-right {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            left: 50%; }
    .preloader .loader .ytp-spinner-circle {
      box-sizing: border-box;
      position: absolute;
      width: 200%;
      height: 100%;
      border-style: solid;
      /* Spinner Color */
      border-color: #2B82D4 #2B82D4 #e9ecef;
      border-radius: 50%;
      border-width: 6px; }
    .preloader .loader .ytp-spinner-left .ytp-spinner-circle {
      left: 0;
      right: -100%;
      border-right-color: #e9ecef;
      -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -moz-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -o-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
    .preloader .loader .ytp-spinner-right .ytp-spinner-circle {
      left: -100%;
      right: 0;
      border-left-color: #e9ecef;
      -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -moz-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      -o-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

/* Preloader Animations */
@-webkit-keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }
@-webkit-keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg); } }
@keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg); } }
@-webkit-keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); } }
@keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg); } }
@-webkit-keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); } }
@keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg); } }
/*===========================
    11.BUTTON css 
===========================*/
.buttons-title .title {
  font-size: 36px;
  line-height: 45px;
  color: #6c6c6c; }
  @media (max-width: 767px) {
    .buttons-title .title {
      font-size: 24px;
      line-height: 35px; } }

.main-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  padding: 0 32px;
  font-size: 16px;
  line-height: 46px;
  color: #2B82D4;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  position: relative;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .main-btn {
      font-size: 14px;
      padding: 0 20px;
      line-height: 40px; } }

/*===== standard Buttons =====*/
.standard-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .standard-buttons ul li {
      margin-left: 0; } }
  .standard-buttons ul li:first-child {
    margin-left: 0; }
.standard-buttons .standard-one {
  border-color: #2B82D4; }
  .standard-buttons .standard-one:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
.standard-buttons .standard-two {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4; }
  .standard-buttons .standard-two:hover {
    color: #2B82D4;
    background-color: transparent; }
.standard-buttons .standard-three {
  overflow: hidden;
  line-height: 50px;
  color: #fff;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  border: 0;
  line-height: 52px;
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .standard-buttons .standard-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .standard-buttons .standard-three {
      line-height: 44px; } }
  .standard-buttons .standard-three:hover {
    background-position: right center; }
.standard-buttons .standard-four {
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .standard-buttons .standard-four {
      padding-left: 40px; } }
  .standard-buttons .standard-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .standard-buttons .standard-four span {
        left: 15px; } }
  .standard-buttons .standard-four:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
    .standard-buttons .standard-four:hover i {
      animation: iconTranslateY 0.5s forwards; }
.standard-buttons .standard-five {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .standard-buttons .standard-five {
      padding-left: 40px; } }
  .standard-buttons .standard-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .standard-buttons .standard-five span {
        left: 15px; } }
  .standard-buttons .standard-five:hover {
    color: #2B82D4;
    background-color: transparent; }
    .standard-buttons .standard-five:hover i {
      animation: iconTranslateY 0.5s forwards; }
.standard-buttons .standard-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 50px;
  color: #fff;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  border: 0;
  line-height: 52px;
  background-size: 200% auto; }
  @media (max-width: 767px) {
    .standard-buttons .standard-six {
      padding-right: 40px;
      line-height: 44px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .standard-buttons .standard-six {
      line-height: 44px; } }
  .standard-buttons .standard-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .standard-buttons .standard-six span {
        right: 15px; } }
  .standard-buttons .standard-six:hover {
    background-position: right center; }
    .standard-buttons .standard-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

/*===== Light Rounded Buttons =====*/
.light-rounded-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .light-rounded-buttons ul li {
      margin-left: 0; } }
  .light-rounded-buttons ul li:first-child {
    margin-left: 0; }
.light-rounded-buttons .main-btn {
  border-radius: 5px; }
.light-rounded-buttons .light-rounded-one {
  border-color: #2B82D4; }
  .light-rounded-buttons .light-rounded-one:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
.light-rounded-buttons .light-rounded-two {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4; }
  .light-rounded-buttons .light-rounded-two:hover {
    color: #2B82D4;
    background-color: transparent; }
.light-rounded-buttons .light-rounded-three {
  overflow: hidden;
  line-height: 50px;
  color: #fff;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  line-height: 52px;
  border: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .light-rounded-buttons .light-rounded-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .light-rounded-buttons .light-rounded-three {
      line-height: 44px; } }
  .light-rounded-buttons .light-rounded-three:hover {
    background-position: right center; }
.light-rounded-buttons .light-rounded-four {
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .light-rounded-buttons .light-rounded-four {
      padding-left: 40px; } }
  .light-rounded-buttons .light-rounded-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .light-rounded-buttons .light-rounded-four span {
        left: 15px; } }
  .light-rounded-buttons .light-rounded-four:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
    .light-rounded-buttons .light-rounded-four:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.light-rounded-buttons .light-rounded-five {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .light-rounded-buttons .light-rounded-five {
      padding-left: 40px; } }
  .light-rounded-buttons .light-rounded-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .light-rounded-buttons .light-rounded-five span {
        left: 15px; } }
  .light-rounded-buttons .light-rounded-five:hover {
    color: #2B82D4;
    background-color: transparent; }
    .light-rounded-buttons .light-rounded-five:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.light-rounded-buttons .light-rounded-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 50px;
  color: #fff;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  line-height: 52px;
  border: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .light-rounded-buttons .light-rounded-six {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .light-rounded-buttons .light-rounded-six {
      padding-right: 40px;
      line-height: 44px; } }
  .light-rounded-buttons .light-rounded-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .light-rounded-buttons .light-rounded-six span {
        right: 15px; } }
  .light-rounded-buttons .light-rounded-six:hover {
    background-position: right center; }
    .light-rounded-buttons .light-rounded-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

/*===== Semi Rounded Buttons =====*/
.semi-rounded-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .semi-rounded-buttons ul li {
      margin-left: 0; } }
  .semi-rounded-buttons ul li:first-child {
    margin-left: 0; }
.semi-rounded-buttons .main-btn {
  border-radius: 10px; }
.semi-rounded-buttons .semi-rounded-one {
  border-color: #2B82D4; }
  .semi-rounded-buttons .semi-rounded-one:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
.semi-rounded-buttons .semi-rounded-two {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4; }
  .semi-rounded-buttons .semi-rounded-two:hover {
    color: #2B82D4;
    background-color: transparent; }
.semi-rounded-buttons .semi-rounded-three {
  overflow: hidden;
  line-height: 52px;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  border: 0;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .semi-rounded-buttons .semi-rounded-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .semi-rounded-buttons .semi-rounded-three {
      line-height: 44px; } }
  .semi-rounded-buttons .semi-rounded-three:hover {
    background-position: right center; }
.semi-rounded-buttons .semi-rounded-four {
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .semi-rounded-buttons .semi-rounded-four {
      padding-left: 40px; } }
  .semi-rounded-buttons .semi-rounded-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .semi-rounded-buttons .semi-rounded-four span {
        left: 15px; } }
  .semi-rounded-buttons .semi-rounded-four:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
    .semi-rounded-buttons .semi-rounded-four:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.semi-rounded-buttons .semi-rounded-five {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .semi-rounded-buttons .semi-rounded-five {
      padding-left: 40px; } }
  .semi-rounded-buttons .semi-rounded-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .semi-rounded-buttons .semi-rounded-five span {
        left: 15px; } }
  .semi-rounded-buttons .semi-rounded-five:hover {
    color: #2B82D4;
    background-color: transparent; }
    .semi-rounded-buttons .semi-rounded-five:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.semi-rounded-buttons .semi-rounded-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 52px;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  border: 0;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .semi-rounded-buttons .semi-rounded-six {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .semi-rounded-buttons .semi-rounded-six {
      padding-right: 40px;
      line-height: 44px; } }
  .semi-rounded-buttons .semi-rounded-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .semi-rounded-buttons .semi-rounded-six span {
        right: 15px; } }
  .semi-rounded-buttons .semi-rounded-six:hover {
    background-position: right center; }
    .semi-rounded-buttons .semi-rounded-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

/*===== Rounded Buttons =====*/
.rounded-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .rounded-buttons ul li {
      margin-left: 0; } }
  .rounded-buttons ul li:first-child {
    margin-left: 0; }
.rounded-buttons .main-btn {
  border-radius: 50px; }
.rounded-buttons .rounded-one {
  border-color: #2B82D4; }
  .rounded-buttons .rounded-one:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
.rounded-buttons .rounded-two {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4; }
  .rounded-buttons .rounded-two:hover {
    color: #2B82D4;
    background-color: transparent; }
.rounded-buttons .rounded-three {
  overflow: hidden;
  line-height: 52px;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  color: #fff;
  border: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rounded-buttons .rounded-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .rounded-buttons .rounded-three {
      line-height: 44px; } }
  .rounded-buttons .rounded-three:hover {
    background-position: right center; }
.rounded-buttons .rounded-four {
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .rounded-buttons .rounded-four {
      padding-left: 40px; } }
  .rounded-buttons .rounded-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .rounded-buttons .rounded-four span {
        left: 15px; } }
  .rounded-buttons .rounded-four:hover {
    color: #2B82D4;
    background-color: rgba(0, 103, 244, 0.4); }
    .rounded-buttons .rounded-four:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.rounded-buttons .rounded-five {
  color: #fff;
  background-color: #2B82D4;
  border-color: #2B82D4;
  padding-left: 60px; }
  @media (max-width: 767px) {
    .rounded-buttons .rounded-five {
      padding-left: 40px; } }
  .rounded-buttons .rounded-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .rounded-buttons .rounded-five span {
        left: 15px; } }
  .rounded-buttons .rounded-five:hover {
    color: #2B82D4;
    background-color: transparent; }
    .rounded-buttons .rounded-five:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.rounded-buttons .rounded-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 52px;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  color: #fff;
  border: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rounded-buttons .rounded-six {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .rounded-buttons .rounded-six {
      padding-right: 40px;
      line-height: 44px; } }
  .rounded-buttons .rounded-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .rounded-buttons .rounded-six span {
        right: 15px; } }
  .rounded-buttons .rounded-six:hover {
    background-position: right center; }
    .rounded-buttons .rounded-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

/*===== Success Buttons =====*/
.success-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .success-buttons ul li {
      margin-left: 0; } }
  .success-buttons ul li:first-child {
    margin-left: 0; }
.success-buttons .success-one {
  border-color: #4da422;
  color: #4da422; }
  .success-buttons .success-one:hover {
    color: #4da422;
    background-color: rgba(77, 164, 34, 0.4); }
.success-buttons .success-two {
  color: #fff;
  background-color: #4da422;
  border-color: #4da422; }
  .success-buttons .success-two:hover {
    color: #4da422;
    background-color: transparent; }
.success-buttons .success-three {
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #4da422 0%, #69e02e 50%, #4da422 100%);
  background: -o-linear-gradient(left, #4da422 0%, #69e02e 50%, #4da422 100%);
  background: linear-gradient(to right, #4da422 0%, #69e02e 50%, #4da422 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .success-buttons .success-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .success-buttons .success-three {
      line-height: 44px; } }
  .success-buttons .success-three:hover {
    background-position: right center; }
.success-buttons .success-four {
  padding-left: 60px;
  border-color: #4da422;
  color: #4da422; }
  @media (max-width: 767px) {
    .success-buttons .success-four {
      padding-left: 40px; } }
  .success-buttons .success-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .success-buttons .success-four span {
        left: 15px; } }
  .success-buttons .success-four:hover {
    color: #4da422;
    background-color: rgba(77, 164, 34, 0.4); }
    .success-buttons .success-four:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.success-buttons .success-five {
  padding-left: 60px;
  color: #fff;
  background-color: #4da422;
  border-color: #4da422; }
  @media (max-width: 767px) {
    .success-buttons .success-five {
      padding-left: 40px; } }
  .success-buttons .success-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .success-buttons .success-five span {
        left: 15px; } }
  .success-buttons .success-five:hover {
    color: #4da422;
    background-color: transparent; }
    .success-buttons .success-five:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.success-buttons .success-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #4da422 0%, #69e02e 50%, #4da422 100%);
  background: -o-linear-gradient(left, #4da422 0%, #69e02e 50%, #4da422 100%);
  background: linear-gradient(to right, #4da422 0%, #69e02e 50%, #4da422 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .success-buttons .success-six {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .success-buttons .success-six {
      padding-right: 40px;
      line-height: 44px; } }
  .success-buttons .success-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .success-buttons .success-six span {
        right: 15px; } }
  .success-buttons .success-six:hover {
    background-position: right center; }
    .success-buttons .success-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

/*===== Warning Buttons =====*/
.warning-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .warning-buttons ul li {
      margin-left: 0; } }
  .warning-buttons ul li:first-child {
    margin-left: 0; }
.warning-buttons .warning-one {
  border-color: #ffb400;
  color: #ffb400; }
  .warning-buttons .warning-one:hover {
    color: #ffb400;
    background-color: rgba(255, 180, 0, 0.4); }
.warning-buttons .warning-two {
  color: #fff;
  background-color: #ffb400;
  border-color: #ffb400; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .warning-buttons .warning-two {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .warning-buttons .warning-two {
      padding-right: 40px;
      line-height: 44px; } }
  .warning-buttons .warning-two:hover {
    color: #ffb400;
    background-color: transparent; }
.warning-buttons .warning-three {
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #ffb400 0%, #f7e500 50%, #ffb400 100%);
  background: -o-linear-gradient(left, #ffb400 0%, #f7e500 50%, #ffb400 100%);
  background: linear-gradient(to right, #ffb400 0%, #f7e500 50%, #ffb400 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .warning-buttons .warning-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .warning-buttons .warning-three {
      line-height: 44px; } }
  .warning-buttons .warning-three:hover {
    background-position: right center; }
.warning-buttons .warning-four {
  padding-left: 60px;
  border-color: #ffb400;
  color: #ffb400; }
  @media (max-width: 767px) {
    .warning-buttons .warning-four {
      padding-left: 40px; } }
  .warning-buttons .warning-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .warning-buttons .warning-four span {
        left: 15px; } }
  .warning-buttons .warning-four:hover {
    color: #ffb400;
    background-color: rgba(255, 180, 0, 0.4); }
    .warning-buttons .warning-four:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.warning-buttons .warning-five {
  padding-left: 60px;
  color: #fff;
  background-color: #ffb400;
  border-color: #ffb400; }
  @media (max-width: 767px) {
    .warning-buttons .warning-five {
      padding-left: 40px; } }
  .warning-buttons .warning-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .warning-buttons .warning-five span {
        left: 15px; } }
  .warning-buttons .warning-five:hover {
    color: #ffb400;
    background-color: transparent; }
    .warning-buttons .warning-five:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.warning-buttons .warning-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #ffb400 0%, #f7e500 50%, #ffb400 100%);
  background: -o-linear-gradient(left, #ffb400 0%, #f7e500 50%, #ffb400 100%);
  background: linear-gradient(to right, #ffb400 0%, #f7e500 50%, #ffb400 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .warning-buttons .warning-six {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .warning-buttons .warning-six {
      padding-right: 40px;
      line-height: 44px; } }
  .warning-buttons .warning-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .warning-buttons .warning-six span {
        right: 15px; } }
  .warning-buttons .warning-six:hover {
    background-position: right center; }
    .warning-buttons .warning-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

/*===== Info Buttons =====*/
.info-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .info-buttons ul li {
      margin-left: 0; } }
  .info-buttons ul li:first-child {
    margin-left: 0; }
.info-buttons .info-one {
  border-color: #00b8d8;
  color: #00b8d8; }
  .info-buttons .info-one:hover {
    color: #00b8d8;
    background-color: rgba(0, 184, 216, 0.4); }
.info-buttons .info-two {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8; }
  .info-buttons .info-two:hover {
    color: #00b8d8;
    background-color: transparent; }
.info-buttons .info-three {
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #00b8d8 0%, #32fbfc 50%, #00b8d8 100%);
  background: -o-linear-gradient(left, #00b8d8 0%, #32fbfc 50%, #00b8d8 100%);
  background: linear-gradient(to right, #00b8d8 0%, #32fbfc 50%, #00b8d8 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-buttons .info-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .info-buttons .info-three {
      line-height: 44px; } }
  .info-buttons .info-three:hover {
    background-position: right center; }
.info-buttons .info-four {
  padding-left: 60px;
  border-color: #00b8d8;
  color: #00b8d8; }
  @media (max-width: 767px) {
    .info-buttons .info-four {
      padding-left: 40px; } }
  .info-buttons .info-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .info-buttons .info-four span {
        left: 15px; } }
  .info-buttons .info-four:hover {
    color: #00b8d8;
    background-color: rgba(0, 184, 216, 0.4); }
    .info-buttons .info-four:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.info-buttons .info-five {
  padding-left: 60px;
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8; }
  @media (max-width: 767px) {
    .info-buttons .info-five {
      padding-left: 40px; } }
  .info-buttons .info-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .info-buttons .info-five span {
        left: 15px; } }
  .info-buttons .info-five:hover {
    color: #00b8d8;
    background-color: transparent; }
    .info-buttons .info-five:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.info-buttons .info-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #00b8d8 0%, #32fbfc 50%, #00b8d8 100%);
  background: -o-linear-gradient(left, #00b8d8 0%, #32fbfc 50%, #00b8d8 100%);
  background: linear-gradient(to right, #00b8d8 0%, #32fbfc 50%, #00b8d8 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-buttons .info-six {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .info-buttons .info-six {
      padding-right: 40px;
      line-height: 44px; } }
  .info-buttons .info-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .info-buttons .info-six span {
        right: 15px; } }
  .info-buttons .info-six:hover {
    background-position: right center; }
    .info-buttons .info-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

/*===== Danger Buttons =====*/
.danger-buttons ul li {
  display: inline-block;
  margin-left: 18px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .danger-buttons ul li {
      margin-left: 0; } }
  .danger-buttons ul li:first-child {
    margin-left: 0; }
.danger-buttons .danger-one {
  border-color: #fc3832;
  color: #fc3832; }
  .danger-buttons .danger-one:hover {
    color: #fc3832;
    background-color: rgba(252, 56, 50, 0.4); }
.danger-buttons .danger-two {
  color: #fff;
  background-color: #fc3832;
  border-color: #fc3832; }
  .danger-buttons .danger-two:hover {
    color: #fc3832;
    background-color: transparent; }
.danger-buttons .danger-three {
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #fc3832 0%, #dc312b 50%, #fc3832 100%);
  background: -o-linear-gradient(left, #fc3832 0%, #dc312b 50%, #fc3832 100%);
  background: linear-gradient(to right, #fc3832 0%, #dc312b 50%, #fc3832 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .danger-buttons .danger-three {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .danger-buttons .danger-three {
      line-height: 44px; } }
  .danger-buttons .danger-three:hover {
    background-position: right center; }
.danger-buttons .danger-four {
  padding-left: 60px;
  border-color: #fc3832;
  color: #fc3832; }
  @media (max-width: 767px) {
    .danger-buttons .danger-four {
      padding-left: 40px; } }
  .danger-buttons .danger-four span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .danger-buttons .danger-four span {
        left: 15px; } }
  .danger-buttons .danger-four:hover {
    color: #fc3832;
    background-color: rgba(252, 56, 50, 0.4); }
    .danger-buttons .danger-four:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.danger-buttons .danger-five {
  padding-left: 60px;
  color: #fff;
  background-color: #fc3832;
  border-color: #fc3832; }
  @media (max-width: 767px) {
    .danger-buttons .danger-five {
      padding-left: 40px; } }
  .danger-buttons .danger-five span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    left: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .danger-buttons .danger-five span {
        left: 15px; } }
  .danger-buttons .danger-five:hover {
    color: #fc3832;
    background-color: transparent; }
    .danger-buttons .danger-five:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }
.danger-buttons .danger-six {
  padding-right: 60px;
  overflow: hidden;
  line-height: 52px;
  color: #fff;
  border: 0;
  background: -webkit-linear-gradient(left, #fc3832 0%, #dc312b 50%, #fc3832 100%);
  background: -o-linear-gradient(left, #fc3832 0%, #dc312b 50%, #fc3832 100%);
  background: linear-gradient(to right, #fc3832 0%, #dc312b 50%, #fc3832 100%);
  background-size: 200% auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .danger-buttons .danger-six {
      line-height: 44px; } }
  @media (max-width: 767px) {
    .danger-buttons .danger-six {
      padding-right: 40px;
      line-height: 44px; } }
  .danger-buttons .danger-six span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    right: 30px;
    line-height: normal; }
    @media (max-width: 767px) {
      .danger-buttons .danger-six span {
        right: 15px; } }
  .danger-buttons .danger-six:hover {
    background-position: right center; }
    .danger-buttons .danger-six:hover i {
      -webkit-animation: iconTranslateY 0.5s forwards;
      -moz-animation: iconTranslateY 0.5s forwards;
      -o-animation: iconTranslateY 0.5s forwards;
      animation: iconTranslateY 0.5s forwards; }

@-webkit-keyframes iconTranslateY {
  49% {
    -webkit-transform: translateY(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  51% {
    opacity: 1; } }
@-moz-keyframes iconTranslateY {
  49% {
    -webkit-transform: translateY(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  51% {
    opacity: 1; } }
@keyframes iconTranslateY {
  49% {
    -webkit-transform: translateY(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  51% {
    opacity: 1; } }
.lg-btn {
  line-height: 64px !important;
  font-size: 18px; }
  @media (max-width: 767px) {
    .lg-btn {
      font-size: 16px;
      ine-height: 52px; } }

.el-btn {
  line-height: 80px !important;
  font-size: 20px; }
  @media (max-width: 767px) {
    .el-btn {
      font-size: 18px;
      line-height: 74px; } }

.sm-btn {
  line-height: 40px !important;
  font-size: 12px; }

/*===== Regular Icon Buttons =====*/
.regular-icon-buttons ul li {
  display: inline-block;
  margin-left: 10px;
  margin-top: 20px; }
  .regular-icon-buttons ul li:first-child {
    margin-left: 0; }
  .regular-icon-buttons ul li .regular-icon-light-one {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    color: #2B82D4; }
    .regular-icon-buttons ul li .regular-icon-light-one:hover {
      color: #fff;
      background-color: #2B82D4; }
  .regular-icon-buttons ul li .regular-icon-light-two {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    color: #2B82D4; }
    .regular-icon-buttons ul li .regular-icon-light-two:hover {
      color: #fff;
      background-color: #2B82D4; }
  .regular-icon-buttons ul li .regular-icon-light-three {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    border-radius: 10px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    color: #2B82D4; }
    .regular-icon-buttons ul li .regular-icon-light-three:hover {
      color: #fff;
      background-color: #2B82D4; }
  .regular-icon-buttons ul li .regular-icon-light-four {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden; }
    .regular-icon-buttons ul li .regular-icon-light-four:hover {
      color: #fff;
      background-color: #2B82D4; }
  .regular-icon-buttons ul li .regular-icon-light-five {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 0;
    text-align: center;
    font-size: 24px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 5;
    overflow: hidden;
    color: #fff;
    background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background-size: 200% auto; }
    .regular-icon-buttons ul li .regular-icon-light-five:hover {
      background-position: right center; }
  .regular-icon-buttons ul li .regular-icon-light-six {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 0;
    text-align: center;
    font-size: 24px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 5;
    overflow: hidden;
    color: #fff;
    background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background-size: 200% auto;
    border-radius: 5px; }
    .regular-icon-buttons ul li .regular-icon-light-six:hover {
      background-position: right center; }
  .regular-icon-buttons ul li .regular-icon-light-seven {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 0;
    text-align: center;
    font-size: 24px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 5;
    overflow: hidden;
    color: #fff;
    background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background-size: 200% auto;
    border-radius: 10px; }
    .regular-icon-buttons ul li .regular-icon-light-seven:hover {
      background-position: right center; }
  .regular-icon-buttons ul li .regular-icon-light-eight {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 0;
    text-align: center;
    font-size: 24px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 5;
    overflow: hidden;
    color: #fff;
    background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
    background-size: 200% auto;
    border-radius: 50%; }
    .regular-icon-buttons ul li .regular-icon-light-eight:hover {
      background-position: right center; }
  .regular-icon-buttons ul li .regular-icon-light-nine {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    color: #fff;
    background-color: #2B82D4; }
    .regular-icon-buttons ul li .regular-icon-light-nine:hover {
      color: #2B82D4;
      background-color: transparent; }
  .regular-icon-buttons ul li .regular-icon-light-ten {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    color: #fff;
    background-color: #2B82D4; }
    .regular-icon-buttons ul li .regular-icon-light-ten:hover {
      color: #2B82D4;
      background-color: transparent; }
  .regular-icon-buttons ul li .regular-icon-light-eleven {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    border-radius: 10px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    color: #fff;
    background-color: #2B82D4; }
    .regular-icon-buttons ul li .regular-icon-light-eleven:hover {
      color: #2B82D4;
      background-color: transparent; }
  .regular-icon-buttons ul li .regular-icon-light-twelve {
    width: 40px;
    height: 40px;
    line-height: 36px;
    border: 2px solid #2B82D4;
    text-align: center;
    font-size: 24px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    color: #fff;
    background-color: #2B82D4; }
    .regular-icon-buttons ul li .regular-icon-light-twelve:hover {
      color: #2B82D4;
      background-color: transparent; }

/*===== Group Buttons =====*/
.group-buttons .btn-group {
  margin-left: 30px; }
.group-buttons .group-one {
  margin-left: 0; }
  .group-buttons .group-one .main-btn {
    background: none;
    border: 2px solid #2B82D4;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    .group-buttons .group-one .main-btn:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: 0; }
    .group-buttons .group-one .main-btn:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 0; }
    .group-buttons .group-one .main-btn:hover {
      background-color: #2B82D4;
      color: #fff; }
.group-buttons .group-two .main-btn {
  background: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  overflow: hidden;
  color: #fff;
  line-height: 52px;
  background: -webkit-linear-gradient(#2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(#2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(#2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: auto 200%;
  border-top: 0;
  border-bottom: 0;
  border-color: rgba(244, 246, 247, 0.2); }
  .group-buttons .group-two .main-btn:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0; }
  .group-buttons .group-two .main-btn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0; }
  .group-buttons .group-two .main-btn:hover {
    background-position: bottom center; }
.group-buttons .group-three .main-btn {
  background: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  overflow: hidden;
  color: #fff;
  line-height: 52px;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  border: 0; }
  .group-buttons .group-three .main-btn:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0; }
  .group-buttons .group-three .main-btn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0; }
  .group-buttons .group-three .main-btn:hover {
    background-position: right center; }
.group-buttons .group-four {
  margin-left: 0; }
  .group-buttons .group-four .main-btn {
    background: none;
    border: 2px solid #2B82D4;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding: 0 12px; }
    .group-buttons .group-four .main-btn i {
      font-size: 24px; }
    .group-buttons .group-four .main-btn:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: 0; }
    .group-buttons .group-four .main-btn:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 0; }
    .group-buttons .group-four .main-btn:hover {
      background-color: #2B82D4;
      color: #fff; }
.group-buttons .group-five .main-btn {
  background: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  overflow: hidden;
  color: #fff;
  line-height: 52px;
  background: -webkit-linear-gradient(#2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(#2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(#2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: auto 200%;
  border-top: 0;
  border-bottom: 0;
  border-color: rgba(244, 246, 247, 0.2);
  padding: 0 16px; }
  .group-buttons .group-five .main-btn:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0; }
  .group-buttons .group-five .main-btn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0; }
  .group-buttons .group-five .main-btn:hover {
    background-position: bottom center; }
.group-buttons .group-six .main-btn {
  background: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  overflow: hidden;
  color: #fff;
  line-height: 52px;
  background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 50%, #2B82D4 100%);
  background-size: 200% auto;
  border: 0;
  padding: 0 16px; }
  .group-buttons .group-six .main-btn:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0; }
  .group-buttons .group-six .main-btn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0; }
  .group-buttons .group-six .main-btn:hover {
    background-position: right center; }

/*===========================
    06.FORM ELEMENTS css 
===========================*/
.form-elements-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  color: #6c6c6c; }
  @media (max-width: 767px) {
    .form-elements-title {
      font-size: 24px;
      line-height: 35px; } }

.form-group {
  margin-bottom: 0; }

.form-input .help-block {
  margin-top: 2px; }
  .form-input .help-block .list-unstyled li {
    font-size: 12px;
    line-height: 16px;
    color: #fc3832; }
.form-input label {
  font-size: 12px;
  line-height: 18px;
  color: #6c6c6c;
  margin-bottom: 8px;
  display: inline-block; }
.form-input .input-items {
  position: relative; }
  .form-input .input-items input, .form-input .input-items textarea {
    width: 100%;
    height: 44px;
    border: 2px solid;
    padding-left: 44px;
    padding-right: 12px;
    position: relative;
    font-size: 16px; }
  .form-input .input-items textarea {
    padding-top: 8px;
    height: 130px;
    resize: none; }
  .form-input .input-items i {
    position: absolute;
    top: 11px;
    left: 13px;
    font-size: 20px;
    z-index: 9; }
  .form-input .input-items.default input, .form-input .input-items.default textarea {
    border-color: #a4a4a4;
    color: #6c6c6c; }
    .form-input .input-items.default input:focus, .form-input .input-items.default textarea:focus {
      border-color: #2B82D4; }
    .form-input .input-items.default input::placeholder, .form-input .input-items.default textarea::placeholder {
      color: #6c6c6c;
      opacity: 1; }
    .form-input .input-items.default input::-moz-placeholder, .form-input .input-items.default textarea::-moz-placeholder {
      color: #6c6c6c;
      opacity: 1; }
    .form-input .input-items.default input::-moz-placeholder, .form-input .input-items.default textarea::-moz-placeholder {
      color: #6c6c6c;
      opacity: 1; }
    .form-input .input-items.default input::-webkit-input-placeholder, .form-input .input-items.default textarea::-webkit-input-placeholder {
      color: #6c6c6c;
      opacity: 1; }
  .form-input .input-items.default i {
    color: #6c6c6c; }
  .form-input .input-items.active input, .form-input .input-items.active textarea {
    border-color: #2B82D4;
    color: #121212; }
    .form-input .input-items.active input::placeholder, .form-input .input-items.active textarea::placeholder {
      color: #121212;
      opacity: 1; }
    .form-input .input-items.active input::-moz-placeholder, .form-input .input-items.active textarea::-moz-placeholder {
      color: #121212;
      opacity: 1; }
    .form-input .input-items.active input::-moz-placeholder, .form-input .input-items.active textarea::-moz-placeholder {
      color: #121212;
      opacity: 1; }
    .form-input .input-items.active input::-webkit-input-placeholder, .form-input .input-items.active textarea::-webkit-input-placeholder {
      color: #121212;
      opacity: 1; }
  .form-input .input-items.active i {
    color: #2B82D4; }
  .form-input .input-items.error input, .form-input .input-items.error textarea {
    border-color: #fc3832;
    color: #fc3832; }
    .form-input .input-items.error input::placeholder, .form-input .input-items.error textarea::placeholder {
      color: #fc3832;
      opacity: 1; }
    .form-input .input-items.error input::-moz-placeholder, .form-input .input-items.error textarea::-moz-placeholder {
      color: #fc3832;
      opacity: 1; }
    .form-input .input-items.error input::-moz-placeholder, .form-input .input-items.error textarea::-moz-placeholder {
      color: #fc3832;
      opacity: 1; }
    .form-input .input-items.error input::-webkit-input-placeholder, .form-input .input-items.error textarea::-webkit-input-placeholder {
      color: #fc3832;
      opacity: 1; }
  .form-input .input-items.error i {
    color: #fc3832; }
  .form-input .input-items.success input, .form-input .input-items.success textarea {
    border-color: #4da422;
    color: #4da422; }
    .form-input .input-items.success input::placeholder, .form-input .input-items.success textarea::placeholder {
      color: #4da422;
      opacity: 1; }
    .form-input .input-items.success input::-moz-placeholder, .form-input .input-items.success textarea::-moz-placeholder {
      color: #4da422;
      opacity: 1; }
    .form-input .input-items.success input::-moz-placeholder, .form-input .input-items.success textarea::-moz-placeholder {
      color: #4da422;
      opacity: 1; }
    .form-input .input-items.success input::-webkit-input-placeholder, .form-input .input-items.success textarea::-webkit-input-placeholder {
      color: #4da422;
      opacity: 1; }
  .form-input .input-items.success i {
    color: #4da422; }
  .form-input .input-items.disabled input, .form-input .input-items.disabled textarea {
    border-color: #a4a4a4;
    color: #6c6c6c;
    background: none; }
    .form-input .input-items.disabled input::placeholder, .form-input .input-items.disabled textarea::placeholder {
      color: #6c6c6c;
      opacity: 1; }
    .form-input .input-items.disabled input::-moz-placeholder, .form-input .input-items.disabled textarea::-moz-placeholder {
      color: #6c6c6c;
      opacity: 1; }
    .form-input .input-items.disabled input::-moz-placeholder, .form-input .input-items.disabled textarea::-moz-placeholder {
      color: #6c6c6c;
      opacity: 1; }
    .form-input .input-items.disabled input::-webkit-input-placeholder, .form-input .input-items.disabled textarea::-webkit-input-placeholder {
      color: #6c6c6c;
      opacity: 1; }
  .form-input .input-items.disabled i {
    color: #6c6c6c; }

.form-style-two .form-input .input-items input, .form-style-two .form-input .input-items textarea {
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 44px; }
.form-style-two .form-input .input-items i {
  left: auto;
  right: 13px; }

.form-style-three .form-input {
  text-align: center; }
  .form-style-three .form-input .input-items input, .form-style-three .form-input .input-items textarea {
    border-radius: 50px;
    text-align: center; }

.form-style-four .form-input label {
  padding-left: 44px;
  margin-bottom: 0; }
.form-style-four .form-input .input-items input, .form-style-four .form-input .input-items textarea {
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.form-style-five .form-input {
  position: relative; }
  .form-style-five .form-input label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    z-index: 5;
    padding: 0 5px; }
  .form-style-five .form-input .input-items input, .form-style-five .form-input .input-items textarea {
    border-radius: 5px; }

/*===========================
        09.NAVBAR css 
===========================*/
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 99; }
  .navbar-area .navbar {
    position: relative;
    padding: 0; }
    .navbar-area .navbar .navbar-toggler .toggler-icon {
      width: 30px;
      height: 2px;
      background-color: #fff;
      margin: 5px 0;
      display: block;
      position: relative;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
    .navbar-area .navbar .navbar-toggler.active .toggler-icon:nth-of-type(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 7px; }
    .navbar-area .navbar .navbar-toggler.active .toggler-icon:nth-of-type(2) {
      opacity: 0; }
    .navbar-area .navbar .navbar-toggler.active .toggler-icon:nth-of-type(3) {
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      top: -7px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .navbar-area .navbar .navbar-collapse {
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        background-color: #f4f6f7;
        z-index: 8;
        padding: 10px 0;
        -webkit-box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05);
        -moz-box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05);
        box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05); } }
    @media (max-width: 767px) {
      .navbar-area .navbar .navbar-collapse {
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        background-color: #f4f6f7;
        z-index: 8;
        padding: 10px 0;
        -webkit-box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05);
        -moz-box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05);
        box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05); } }
    .navbar-area .navbar .navbar-nav .nav-item {
      margin: 0 16px;
      position: relative; }
      .navbar-area .navbar .navbar-nav .nav-item a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        padding: 26px 0;
        color: #fff;
        text-transform: uppercase;
        position: relative;
        opacity: 0.8;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .navbar-area .navbar .navbar-nav .nav-item a {
            padding: 10px 0;
            display: block;
            color: #121212; } }
        @media (max-width: 767px) {
          .navbar-area .navbar .navbar-nav .nav-item a {
            padding: 10px 0;
            display: block;
            color: #121212; } }
        .navbar-area .navbar .navbar-nav .nav-item a::before {
          position: absolute;
          content: '';
          width: 32px;
          height: 4px;
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.1) 0%, white 100%);
          background: -o-linear-gradient(left, rgba(255, 255, 255, 0.1) 0%, white 100%);
          background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, white 100%);
          left: 50%;
          margin: 0 2px;
          bottom: 14px;
          -webkit-transition: all 0.3s ease-out 0s;
          -moz-transition: all 0.3s ease-out 0s;
          -ms-transition: all 0.3s ease-out 0s;
          -o-transition: all 0.3s ease-out 0s;
          transition: all 0.3s ease-out 0s;
          -webkit-transform: translate(-50%) scaleX(0);
          -moz-transform: translate(-50%) scaleX(0);
          -ms-transform: translate(-50%) scaleX(0);
          -o-transform: translate(-50%) scaleX(0);
          transform: translate(-50%) scaleX(0); }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .navbar-area .navbar .navbar-nav .nav-item a::before {
              display: none; } }
          @media (max-width: 767px) {
            .navbar-area .navbar .navbar-nav .nav-item a::before {
              display: none; } }
      .navbar-area .navbar .navbar-nav .nav-item.active > a, .navbar-area .navbar .navbar-nav .nav-item:hover > a {
        opacity: 1;
        color: #fff; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .navbar-area .navbar .navbar-nav .nav-item.active > a, .navbar-area .navbar .navbar-nav .nav-item:hover > a {
            color: #121212; } }
        @media (max-width: 767px) {
          .navbar-area .navbar .navbar-nav .nav-item.active > a, .navbar-area .navbar .navbar-nav .nav-item:hover > a {
            color: #121212; } }
        .navbar-area .navbar .navbar-nav .nav-item.active > a::before, .navbar-area .navbar .navbar-nav .nav-item:hover > a::before {
          -webkit-transform: translate(-50%) scaleX(1);
          -moz-transform: translate(-50%) scaleX(1);
          -ms-transform: translate(-50%) scaleX(1);
          -o-transform: translate(-50%) scaleX(1);
          transform: translate(-50%) scaleX(1); }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .navbar-area .navbar .navbar-btn {
        position: absolute;
        right: 70px;
        top: 7px; } }
    @media (max-width: 767px) {
      .navbar-area .navbar .navbar-btn {
        position: absolute;
        right: 60px;
        top: 7px; } }
    .navbar-area .navbar .navbar-btn li {
      display: inline-block;
      margin-right: 5px; }
      .navbar-area .navbar .navbar-btn li a {
        padding: 10px 16px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        border: 2px solid;
        border-radius: 4px;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s; }
        .navbar-area .navbar .navbar-btn li a.light {
          border-color: #fff; }
          .navbar-area .navbar .navbar-btn li a.light:hover {
            background-color: rgba(255, 255, 255, 0.4); }
        .navbar-area .navbar .navbar-btn li a.solid {
          background-color: #fff;
          border-color: #fff;
          color: #2B82D4; }
          .navbar-area .navbar .navbar-btn li a.solid:hover {
            background-color: transparent;
            color: #fff; }
  .navbar-area.sticky {
    background-color: #fff;
    z-index: 999;
    position: fixed;
    -webkit-box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05);
    -moz-box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05);
    box-shadow: 0px 10px 25px 0px rgba(18, 18, 18, 0.05); }
    .navbar-area.sticky .navbar .navbar-toggler .toggler-icon {
      background-color: #121212; }
    .navbar-area.sticky .navbar .navbar-nav .nav-item a {
      color: #121212;
      opacity: 0.7; }
      .navbar-area.sticky .navbar .navbar-nav .nav-item a::before {
        background: -webkit-linear-gradient(left, rgba(18, 18, 18, 0) 0%, #121212 100%);
        background: -o-linear-gradient(left, rgba(18, 18, 18, 0) 0%, #121212 100%);
        background: linear-gradient(to right, rgba(18, 18, 18, 0) 0%, #121212 100%); }
    .navbar-area.sticky .navbar .navbar-nav .nav-item.active, .navbar-area.sticky .navbar .navbar-nav .nav-item:hover {
      color: #121212;
      opacity: 1; }
    .navbar-area.sticky .navbar .navbar-btn li a.light {
      border-color: #2B82D4;
      color: #2B82D4; }
    .navbar-area.sticky .navbar .navbar-btn li a.solid {
      border-color: #2B82D4;
      background-color: #2B82D4;
      color: #fff; }

/*===========================
       10.SLIDER css 
===========================*/
.carousel-item {
  background-color: #2B82D4;
  position: relative; }
  .carousel-item::before {
    position: absolute;
    content: '';
    width: 33%;
    height: 100%;
    background: -webkit-linear-gradient(rgba(0, 103, 244, 0.3) 0%, rgba(43, 219, 220, 0.3) 100%);
    background: -o-linear-gradient(rgba(0, 103, 244, 0.3) 0%, rgba(43, 219, 220, 0.3) 100%);
    background: linear-gradient(rgba(0, 103, 244, 0.3) 0%, rgba(43, 219, 220, 0.3) 100%);
    top: 0;
    right: 15%;
    -webkit-transform: skewX(20deg);
    -moz-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    transform: skewX(20deg); }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .carousel-item::before {
        width: 40%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .carousel-item::before {
        width: 60%; } }
    @media (max-width: 767px) {
      .carousel-item::before {
        width: 50%;
        right: 45%; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .carousel-item::before {
        right: 25%; } }
  .carousel-item .slider-image-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 9; }
    .carousel-item .slider-image-box .slider-image {
      max-width: 680px;
      width: 100%; }

.slider-content {
  position: relative;
  z-index: 9;
  padding-top: 240px;
  padding-bottom: 200px; }
  @media (max-width: 767px) {
    .slider-content {
      padding-top: 190px;
      padding-bottom: 150px; } }
  .slider-content .title {
    font-size: 88px;
    line-height: 96px;
    color: #fff;
    font-weight: 700; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider-content .title {
        font-size: 58px;
        line-height: 80px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-content .title {
        font-size: 72px;
        line-height: 90px; } }
    @media (max-width: 767px) {
      .slider-content .title {
        font-size: 34px;
        line-height: 45px; } }
  .slider-content .text {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px; }
  .slider-content .slider-btn {
    padding-top: 16px; }
    .slider-content .slider-btn li {
      display: inline-block;
      margin: 16px 8px 0; }
      @media (max-width: 767px) {
        .slider-content .slider-btn li {
          margin: 16px 3px 0; } }
      .slider-content .slider-btn li a.rounded-one {
        background-color: #fff;
        border-color: #fff; }
        .slider-content .slider-btn li a.rounded-one:hover {
          background-color: transparent;
          color: #fff; }
      .slider-content .slider-btn li a.rounded-two {
        border-color: #fff; }
        .slider-content .slider-btn li a.rounded-two:hover {
          background-color: #fff;
          color: #2B82D4; }

.carousel-indicators {
  margin-bottom: 50px; }
  .carousel-indicators li {
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    border: 0;
    margin: 0px 2px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .carousel-indicators li {
        width: 13px;
        height: 13px; } }
    @media (max-width: 767px) {
      .carousel-indicators li {
        width: 13px;
        height: 13px; } }
    .carousel-indicators li.active {
      background-color: #fff;
      width: 16px;
      border-radius: 50px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .carousel-indicators li.active {
          width: 23px; } }
      @media (max-width: 767px) {
        .carousel-indicators li.active {
          width: 23px; } }

.carousel .carousel-control-prev, .carousel .carousel-control-next {
  top: 50%;
  font-size: 32px;
  color: #fff;
  bottom: auto;
  left: 60px;
  right: auto;
  opacity: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .carousel .carousel-control-prev, .carousel .carousel-control-next {
      left: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .carousel .carousel-control-prev, .carousel .carousel-control-next {
      display: none; } }
  @media (max-width: 767px) {
    .carousel .carousel-control-prev, .carousel .carousel-control-next {
      display: none; } }
.carousel .carousel-control-next {
  right: 60px;
  left: auto; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .carousel .carousel-control-next {
      right: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .carousel .carousel-control-next {
      right: 30px; } }
  @media (max-width: 767px) {
    .carousel .carousel-control-next {
      right: 30px; } }

/*===========================
      17.FEATURES css 
===========================*/
/*===== features TWO =====*/
.features-area {
  background-color: #f4f6f7;
  padding-top: 120px;
  padding-bottom: 130px; }

.single-features {
  padding: 40px 20px 52px;
  background-color: #fff; }
  .single-features .features-title-icon .features-title a {
    font-size: 36px;
    line-height: 45px;
    color: #121212;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    font-weight: 400; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-features .features-title-icon .features-title a {
        font-size: 24px;
        line-height: 35px; } }
    @media (max-width: 767px) {
      .single-features .features-title-icon .features-title a {
        font-size: 24px;
        line-height: 35px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-features .features-title-icon .features-title a {
        font-size: 36px;
        line-height: 45px; } }
    .single-features .features-title-icon .features-title a:hover {
      color: #2B82D4; }
  .single-features .features-title-icon .features-icon {
    position: relative;
    display: inline-block; }
    .single-features .features-title-icon .features-icon i {
      font-size: 88px;
      line-height: 70px;
      color: #2B82D4;
      position: relative;
      z-index: 5; }
    .single-features .features-title-icon .features-icon .shape {
      position: absolute;
      top: 0;
      left: 0; }
  .single-features .features-content .text {
    font-size: 14px;
    line-height: 20px;
    color: #121212;
    margin-top: 16px; }
  .single-features .features-content .features-btn {
    color: #2B82D4;
    font-size: 16px;
    font-weight: 700;
    margin-top: 29px; }

/*===========================
      18.PORTFOLIO css 
===========================*/
.portfolio-area {
  padding-top: 120px;
  padding-bottom: 130px; }

.portfolio-menu ul li {
  font-size: 16px;
  font-weight: 700;
  color: #6c6c6c;
  line-height: 48px;
  padding: 0 30px;
  position: relative;
  z-index: 5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  cursor: pointer;
  width: 100%;
  border-radius: 50px;
  overflow: hidden;
  margin-top: 4px;
  text-transform: uppercase; }
  .portfolio-menu ul li:last-child {
    margin-right: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-menu ul li {
      font-size: 14px;
      padding: 0 26px; } }
  @media (max-width: 767px) {
    .portfolio-menu ul li {
      font-size: 14px;
      padding: 0 22px;
      line-height: 42px; } }
  .portfolio-menu ul li::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 100%);
    background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 100%);
    background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 100%);
    z-index: -1;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
  .portfolio-menu ul li:hover, .portfolio-menu ul li.active {
    color: #fff; }
    .portfolio-menu ul li:hover::before, .portfolio-menu ul li.active::before {
      opacity: 1; }

.single-portfolio .portfolio-image {
  position: relative;
  overflow: hidden; }
  .single-portfolio .portfolio-image img {
    width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
  .single-portfolio .portfolio-image .portfolio-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    border-radius: 8px; }
    .single-portfolio .portfolio-image .portfolio-overlay .portfolio-content {
      padding: 16px; }
      .single-portfolio .portfolio-image .portfolio-overlay .portfolio-content .portfolio-icon {
        position: relative;
        display: inline-block;
        margin: 0 20px; }
        .single-portfolio .portfolio-image .portfolio-overlay .portfolio-content .portfolio-icon a {
          font-size: 48px;
          color: #2B82D4;
          position: relative;
          z-index: 5;
          line-height: 50px; }
        .single-portfolio .portfolio-image .portfolio-overlay .portfolio-content .portfolio-icon .shape {
          position: absolute;
          top: 7px;
          left: 0; }
.single-portfolio:hover .portfolio-overlay {
  opacity: 1;
  visibility: visible; }

/*===========================
      16.PRICING css 
===========================*/
.pricing-area {
  background-color: #f4f6f7;
  padding-top: 120px;
  padding-bottom: 130px; }

/*===== PRICING STYLE NINE =====*/
.pricing-style {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 24px 20px 38px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background: -webkit-linear-gradient(#2bdbdc 0%, #2B82D4 100%);
  background: -o-linear-gradient(#2bdbdc 0%, #2B82D4 100%);
  background: linear-gradient(#2bdbdc 0%, #2B82D4 100%); }
  .pricing-style .pricing-icon img {
    width: 190px; }
  .pricing-style .pricing-header .sub-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    color: #fff;
    position: relative;
    margin-top: 24px; }
  .pricing-style .pricing-header .month {
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
    color: #fff;
    margin-top: 3px; }
    .pricing-style .pricing-header .month .price {
      font-size: 36px;
      font-weight: 600;
      line-height: 45px;
      color: #fff;
      margin-top: 8px; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .pricing-style .pricing-header .month .price {
          font-size: 24px;
          margin-top: 32px;
          line-height: 30px; } }
  .pricing-style .pricing-list {
    margin-top: 24px; }
    .pricing-style .pricing-list ul li {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      margin-top: 16px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .pricing-style .pricing-list ul li {
          font-size: 14px;
          margin-top: 12px; } }
      @media (max-width: 767px) {
        .pricing-style .pricing-list ul li {
          font-size: 14px;
          margin-top: 12px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .pricing-style .pricing-list ul li {
          font-size: 16px;
          margin-top: 16px; } }
      .pricing-style .pricing-list ul li i {
        color: #fff;
        margin-right: 8px; }
  .pricing-style .pricing-btn {
    margin-top: 31px; }
    .pricing-style .pricing-btn .main-btn {
      background-color: #fff;
      border-color: #fff; }
      .pricing-style .pricing-btn .main-btn:hover {
        color: #2B82D4;
        -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16); }

/*===========================
       15.ABOUT css 
===========================*/
.about-area {
  padding-top: 80px;
  padding-bottom: 130px;
  position: relative; }
  .about-area .about-title .sub-title {
    font-size: 18px;
    font-weight: 400;
    color: #2B82D4;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .about-area .about-title .sub-title {
        font-size: 16px; } }
  .about-area .about-title .title {
    font-size: 30px;
    padding-top: 10px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about-area .about-title .title {
        font-size: 26px; } }
    @media (max-width: 767px) {
      .about-area .about-title .title {
        font-size: 22px; } }
  .about-area .about-accordion .accordion .card {
    border: 0;
    background: none; }
    .about-area .about-accordion .accordion .card .card-header {
      padding: 0;
      border: 0;
      background: none;
      margin-top: 40px; }
      .about-area .about-accordion .accordion .card .card-header a {
        font-size: 18px;
        font-weight: 500;
        color: #000;
        display: block;
        position: relative;
        padding-right: 20px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .about-area .about-accordion .accordion .card .card-header a {
            font-size: 16px; } }
        @media (max-width: 767px) {
          .about-area .about-accordion .accordion .card .card-header a {
            font-size: 16px; } }
        .about-area .about-accordion .accordion .card .card-header a::before {
          content: '\ea58';
          font-family: 'LineIcons';
          position: absolute;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); }
      .about-area .about-accordion .accordion .card .card-header .collapsed::before {
        content: '\ea5c'; }
    .about-area .about-accordion .accordion .card .card-body {
      padding: 20px 20px 0; }
  .about-area .about-image img {
    width: 100%; }

/*===========================
      26.TESTIMONIAL css 
===========================*/
/*===== TESTIMONIAL STYLE THREE =====*/
.testimonial-area {
  padding-top: 80px;
  padding-bottom: 130px;
  background-color: #f4f6f7; }
  .testimonial-area .testimonial-left-content .sub-title {
    font-size: 18px;
    font-weight: 400;
    color: #2B82D4;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .testimonial-area .testimonial-left-content .sub-title {
        font-size: 16px; } }
  .testimonial-area .testimonial-left-content .title {
    font-size: 32px;
    padding-top: 10px;
    color: #121212; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .testimonial-area .testimonial-left-content .title {
        font-size: 30px; } }
    @media (max-width: 767px) {
      .testimonial-area .testimonial-left-content .title {
        font-size: 24px; } }
  .testimonial-area .testimonial-left-content .testimonial-line {
    padding-top: 10px; }
    .testimonial-area .testimonial-left-content .testimonial-line li {
      height: 5px;
      background-color: #2B82D4;
      opacity: 0.2;
      display: inline-block;
      border-radius: 50px;
      margin-right: 3px; }
      .testimonial-area .testimonial-left-content .testimonial-line li:nth-of-type(1) {
        width: 40px; }
      .testimonial-area .testimonial-left-content .testimonial-line li:nth-of-type(2) {
        width: 15px; }
      .testimonial-area .testimonial-left-content .testimonial-line li:nth-of-type(3) {
        width: 10px; }
      .testimonial-area .testimonial-left-content .testimonial-line li:nth-of-type(4) {
        width: 5px; }
  .testimonial-area .testimonial-left-content .text {
    padding-top: 15px; }
  .testimonial-area .testimonial-right-content {
    position: relative;
    background-color: #e9ecef;
    -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.07);
    border-radius: 50px;
    max-width: 500px;
    padding-top: 50px; }
    .testimonial-area .testimonial-right-content .quota {
      position: absolute;
      top: 10px;
      left: 15px;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      opacity: 0.1; }
      .testimonial-area .testimonial-right-content .quota i {
        font-size: 130px;
        line-height: 95px;
        color: #2B82D4; }
  .testimonial-area .testimonial-content-wrapper {
    position: relative;
    left: 70px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .testimonial-area .testimonial-content-wrapper {
        left: 0; } }
    @media (max-width: 767px) {
      .testimonial-area .testimonial-content-wrapper {
        left: 0; } }

.single-testimonial {
  background-color: #fff;
  padding: 40px 30px 50px;
  border-radius: 50px; }
  .single-testimonial .testimonial-text .text {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    color: #121212;
    padding-bottom: 25px; }
    @media (max-width: 767px) {
      .single-testimonial .testimonial-text .text {
        font-size: 18px;
        line-height: 32px; } }
  .single-testimonial .testimonial-author {
    border-top: 1px solid #e9ecef;
    padding-top: 50px; }
    .single-testimonial .testimonial-author .author-info .author-image img {
      border-radius: 50%;
      width: 70px; }
    .single-testimonial .testimonial-author .author-info .author-name {
      padding-left: 30px; }
      .single-testimonial .testimonial-author .author-info .author-name .name {
        font-size: 16px;
        font-weight: 700;
        color: #121212; }
      .single-testimonial .testimonial-author .author-info .author-name .sub-title {
        font-size: 14px;
        color: #a4a4a4;
        margin-top: 5px; }
    @media (max-width: 767px) {
      .single-testimonial .testimonial-author .author-review {
        padding-left: 100px;
        padding-top: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-testimonial .testimonial-author .author-review {
        padding-left: 0;
        padding-top: 15px; } }
    .single-testimonial .testimonial-author .author-review .star li {
      display: inline-block;
      font-size: 14px;
      color: #ffb400; }
    .single-testimonial .testimonial-author .author-review .review {
      font-size: 14px;
      color: #000;
      margin-top: 5px; }

.testimonial-active .slick-arrow {
  position: absolute;
  bottom: 0;
  left: -635px;
  font-size: 22px;
  cursor: pointer;
  color: #a4a4a4;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-active .slick-arrow {
      left: -480px; } }
  .testimonial-active .slick-arrow:hover {
    color: #000; }
  .testimonial-active .slick-arrow.next {
    left: -600px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .testimonial-active .slick-arrow.next {
        left: -445px; } }

/*===========================
        13.TEAM css 
===========================*/
/*===== TEAM STYLE ELEVEN =====*/
.team-style-eleven {
  position: relative;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1);
  -moz-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1); }
  .team-style-eleven .team-image img {
    width: 100%; }
  .team-style-eleven .team-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding-top: 25px;
    padding-bottom: 25px;
    z-index: 5;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    @media (max-width: 767px) {
      .team-style-eleven .team-content {
        padding-top: 15px;
        padding-bottom: 15px; } }
    .team-style-eleven .team-content::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: -webkit-linear-gradient(left, #2B82D4 0%, #2bdbdc 100%);
      background: -o-linear-gradient(left, #2B82D4 0%, #2bdbdc 100%);
      background: linear-gradient(to right, #2B82D4 0%, #2bdbdc 100%);
      z-index: -1;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
      opacity: 0; }
    .team-style-eleven .team-content .team-social {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
      visibility: hidden;
      opacity: 0; }
      .team-style-eleven .team-content .team-social .social {
        background-color: #fff;
        display: inline-block;
        padding: 10px 20px 6px;
        border-radius: 50px; }
        .team-style-eleven .team-content .team-social .social li {
          display: inline-block;
          margin: 0 8px; }
          .team-style-eleven .team-content .team-social .social li a {
            font-size: 16px;
            color: #a4a4a4;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s; }
            .team-style-eleven .team-content .team-social .social li a:hover {
              color: #2B82D4; }
    .team-style-eleven .team-content .team-name a {
      color: #121212;
      font-size: 24px;
      font-weight: 600;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      @media (max-width: 767px) {
        .team-style-eleven .team-content .team-name a {
          font-size: 18px; } }
    .team-style-eleven .team-content .sub-title {
      font-size: 16px;
      color: #2B82D4;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      @media (max-width: 767px) {
        .team-style-eleven .team-content .sub-title {
          font-size: 14px; } }
  .team-style-eleven:hover .team-content {
    padding-top: 50px; }
    @media (max-width: 767px) {
      .team-style-eleven:hover .team-content {
        padding-top: 35px; } }
    .team-style-eleven:hover .team-content::before {
      opacity: 1; }
    .team-style-eleven:hover .team-content .team-social {
      top: -20px;
      visibility: visible;
      opacity: 1; }
    .team-style-eleven:hover .team-content .team-name a {
      color: #fff; }
    .team-style-eleven:hover .team-content .sub-title {
      color: #fff; }

/*===========================
      19.CONTACT css 
===========================*/
.contact-area {
  padding-top: 35px;
  padding-bottom: 130px;
  background-color: #f4f6f7; }

  .fleet-area {
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #f4f6f7; }

p.form-message.success, p.form-message.error {
  font-size: 16px;
  color: #333;
  background: #ddd;
  padding: 10px 15px;
  margin-top: 15px;
  margin-left: 15px; }
  p.form-message.success.form-message.error, p.form-message.error.form-message.error {
    color: #f00; }

.contact-map iframe {
  width: 100%;
  height: 550px; }
  @media (max-width: 767px) {
    .contact-map iframe {
      height: 350px; } }

.contact-info .single-contact-info .contact-info-icon i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  border-radius: 50%; }
.contact-info .single-contact-info .contact-info-content {
  padding-left: 20px; }
  .contact-info .single-contact-info .contact-info-content .text {
    color: #121212; }
.contact-info .single-contact-info.contact-color-1 .contact-info-icon i {
  background-color: #121212; }
.contact-info .single-contact-info.contact-color-2 .contact-info-icon i {
  background-color: #fc3832; }
.contact-info .single-contact-info.contact-color-3 .contact-info-icon i {
  background-color: #005ad5; }

.contact-wrapper .contact-title {
  font-size: 32px;
  font-weight: 700;
  color: #000; }
  @media (max-width: 767px) {
    .contact-wrapper .contact-title {
      font-size: 26px; } }
  .contact-wrapper .contact-title i {
    color: #121212;
    margin-right: 8px; }
  .contact-wrapper .contact-title span {
    font-weight: 400; }

/*===========================
    21.FOOTER css 
===========================*/
/*===== FOOTER FIVE =====*/
.footer-area {
  background-color: #f4f6f7;
  padding-top: 90px;
  padding-bottom: 120px; }
  .footer-area .social li {
    display: inline-block;
    margin: 0 5px; }
    .footer-area .social li a {
      font-size: 24px;
      color: #6c6c6c;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      .footer-area .social li a:hover {
        color: #2B82D4; }
  .footer-area .footer-support {
    padding-top: 21px; }
    .footer-area .footer-support span {
      font-size: 16px;
      line-height: 24px;
      color: #121212;
      font-weight: 600;
      margin-top: 9px;
      display: block; }
      @media (max-width: 767px) {
        .footer-area .footer-support span {
          display: block; } }
  .footer-area .footer-app-store {
    padding-top: 27px; }
    .footer-area .footer-app-store ul li {
      display: inline-block;
      margin-right: 8px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .footer-area .footer-app-store ul li {
          margin-right: 6px; } }
      .footer-area .footer-app-store ul li:last-child {
        margin-right: 0; }
      @media (max-width: 767px) {
        .footer-area .footer-app-store ul li {
          width: 120px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .footer-area .footer-app-store ul li {
          width: auto; } }
  .footer-area .copyright .text {
    color: #121212; }
  .footer-area.footer-dark {
    background-color: #2B82D4; }
    .footer-area.footer-dark .social li a {
      color: #fff; }
    .footer-area.footer-dark .footer-support {
      padding-top: 21px; }
      .footer-area.footer-dark .footer-support span {
        color: #fff; }
    .footer-area.footer-dark .footer-app-store {
      padding-top: 27px; }
      .footer-area.footer-dark .footer-app-store ul li {
        color: #fff; }
    .footer-area.footer-dark .copyright .text {
      color: #fff; }

/*===== BACK TO TOP =====*/
.back-to-top {
  font-size: 20px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 5px;
  background-color: #2B82D4;
  text-align: center;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: none; }
  .back-to-top:hover {
    color: #fff;
    background-color: #2B82D4; }


    .previous {
      background-color: #2B82D4;
      color: white;
    }
  



